import React from "react";
import { Trans } from "react-i18next";
import classes from './StayIn.module.scss';
import { useTranslation } from "react-i18next";

const StayIn = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.stayIn}>
                <h1 className={[classes.title, 'font-64'].join(' ')}>
                    <Trans>{t('education_banner_title')}</Trans>
                </h1>
                <h3 className={[classes.subtitle, 'font-24'].join(' ')}>
                    <Trans>education_banner_subtitle</Trans>
                </h3>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    <Trans>education_banner_text</Trans>
                </p>
            </div>
        </section>
    );
};

export default StayIn;

import React, { useState } from "react";
import classes from "./Stages.module.scss";
import { v4 as uuidv4 } from "uuid";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const Stages = () => {
  const { t } = useTranslation();
  const [tabs, setTabs] = useState([
    {
      id: 1,
      title: "stages_01_title",
      itemTitle: "stages_01_subtitle",
      itemSubtitle:
        "stages_01_text",
      content: [
        ["stages_table_row_01", "0.2", "stages_table_row_05", "0.8"],
        ["stages_table_row_02", "0.1", "stages_table_row_06", "0.8"],
        ["stages_table_row_03", "[DAX40]", "stages_table_row_07", "-0.00856"],
        [
          "stages_table_row_04",
          "0.8",
          "stages_table_row_07",
          "-0.00856",
        ],
      ],
      active: true,
    },
    {
      id: 2,
      title: "stages_02_title",
      itemTitle:
        "stages_02_subtitle",
      itemSubtitle:
        "stages_02_text",
      secondRow: [
        { title: "stages_02_card_01_title", text: "stages_02_card_01_text" },
        { title: "stages_02_card_02_title", text: "stages_02_card_02_text" },
        { title: "stages_02_card_03_title", text: "stages_02_card_03_text" },
      ],
      content: [
        ["stages_table_row_01", "0.2", "stages_table_row_05", "0.20"],
        ["stages_table_row_02", "0.1", "stages_table_row_06", "0.20"],
        ["stages_table_row_03", "GOLD", "stages_table_row_07", "-11, 225"],
        [
          "stages_table_row_04",
          "0.10",
          "stages_table_row_07",
          "6,562",
        ],
      ],
      active: false,
    },
    {
      id: 3,
      title: "stages_03_title",

      content: [
        ["stages_table_row_01", "1", "stages_table_row_05", "0.03"],
        ["stages_table_row_02", "0", "stages_table_row_06", "0.03"],
        ["stages_table_row_03", "CRUDOIL", "stages_table_row_07", "-0.055"],
        [
          "stages_table_row_04",
          "0.03",
          "stages_table_row_07",
          "-0.01736",
        ],
      ],
      active: false,
    },
    {
      id: 4,
      title: "stages_04_title",
      title2: "stages_04_subtitle",
      content: [
        ["stages_table_04_row_01", "1:500", "stages_table_04_row_05", "0.00006"],
        ["stages_table_04_row_02", "0.1", "stages_table_04_row_06", "0.00006"],
        ["stages_table_04_row_03", "EURUSD", "stages_table_04_row_07", "-0.829"],
        ["stages_table_04_row_04", "0.00001", "stages_table_04_row_08", "0.495"],
      ],
      active: false,
    },
  ]);

  return (
    <div className={classes.stages}>
      <div className={classes.stagesBody}>
        <div className="container">
          <div className={classes.stagesWrap}>
            <div className={classes.stagesTopWrapper}>
              <div className={classes.stagesTop}>
                {tabs.map((topI) => {
                  return (
                    <div
                      key={topI.id + 5}
                      onClick={() => {
                        setTabs(
                          tabs.map((tabI) => {
                            if (topI.id === tabI.id) {
                              return { ...tabI, active: true };
                            } else {
                              return { ...tabI, active: false };
                            }
                          })
                        );
                      }}
                      className={
                        topI.active
                          ? [
                              classes.stagesTopColumn,
                              classes.active,
                              "font-24",
                            ].join(" ")
                          : [classes.stagesTopColumn, "font-24"].join(" ")
                      }
                    >
                      <span>
                        <Trans>
                          {t(topI.title)}
                        </Trans>
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>

            {tabs.map((centerI) => {
              return (
                <div
                  key={uuidv4()}
                  className={
                    centerI.active
                      ? [classes.stagesCenter, classes.active].join(" ")
                      : classes.stagesCenter
                  }
                >
                  {centerI.title2 && (
                    <div
                      className={[classes.stagesRowOverTitle, "font-16"].join(
                        " "
                      )}
                    >
                      <Trans>
                        {centerI.title2}
                      </Trans>
                    </div>
                  )}
                  {!centerI.title2 && (
                    <div
                      className={[classes.stagesRowOverTitle, "font-16"].join(
                        " "
                      )}
                    >
                      <Trans>
                        {centerI.title}
                      </Trans>
                    </div>
                  )}
                  {centerI.itemTitle && (
                    <div
                      className={[classes.stagesRowTitle, "font-32"].join(" ")}
                    >
                      <Trans>
                        {centerI.itemTitle}
                      </Trans>
                    </div>
                  )}
                  {centerI.itemSubtitle && (
                    <div
                      className={[classes.stagesRowSubtitle, "font-20"].join(
                        " "
                      )}
                    >
                      <Trans>
                        {centerI.itemSubtitle}
                      </Trans>
                    </div>
                  )}

                  {centerI.secondRow && (
                    <div className={[classes.stagesRowSecond].join(" ")}>
                      {centerI.secondRow.map((sRI) => {
                        return (
                          <div
                            key={uuidv4()}
                            className={classes.stagesRowSecondColumn}
                          >
                            <div
                              className={[
                                classes.stagesRowSecondColumnTitle,
                                "font-32",
                              ].join(" ")}
                            >
                              <Trans>
                                {sRI.title}
                              </Trans>
                            </div>
                            <div
                              className={[
                                classes.stagesRowSecondColumnSubtitle,
                                "font-24",
                              ].join(" ")}
                            >
                              <Trans>
                                {sRI.text}
                              </Trans>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className={classes.stagesRowWrapperScroll}>
                    <div className={classes.stagesRowWrapper}>
                      {centerI.content.map((subI) => {
                        return (
                          <div className={classes.stagesRow} key={uuidv4()}>
                            {subI.map((listI) => {
                              return (
                                <div
                                  key={uuidv4()}
                                  className={[
                                    "font-20",
                                    classes.stagesRowColumn,
                                  ].join(" ")}
                                >
                                  <Trans>
                                    {listI}
                                  </Trans>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stages;

import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Welcome.module.scss";
import img1 from "../../../assets/img/about/1.png";
import img2 from "../../../assets/img/about/mob1.png";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const Welcome = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.welcome}>
      <div className={classes.welcomeBody}>
        <div className={["container", classes.cont].join(" ")}>
          <div className={classes.welcomeRow}>
            <div className={classes.welcomeRowLeft}>
              <h2 className={[classes.welcomeTitle, "font-40"].join(" ")}>
                <Trans>about_banner_title</Trans>
              </h2>
              <h3 className={[classes.welcomeSubtitle, "font-24"].join(" ")}>
                <Trans>about_banner_subtitle</Trans>
              </h3>
              <p className={[classes.welcomeText, "font-20"].join(" ")}>
                <Trans>about_banner_text_01</Trans>
              </p>
              <p className={[classes.welcomeText, "font-20"].join(" ")}>
                <Trans>about_banner_text_02</Trans>
              </p>
              <p className={[classes.welcomeText, "font-20"].join(" ")}>
                <Trans>about_banner_text_03</Trans>
              </p>
              <div className={classes.welcomeBtn}>
                <Button>
                  <Trans>{t('btn_start_trading')}</Trans>
                </Button>
              </div>
            </div>
            <div className={classes.welcomeRowRight}>
              <img src={img1} alt="" />
            </div>
            <div className={[classes.welcomeRowRight, classes.mob].join(" ")}>
              <img src={img2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;

import React from "react";
import classes from "./Team.module.scss";
import img1 from "../../../assets/img/about/2.png";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const Team = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.team}>
      <div className={classes.teamBody}>
        <div className={["container", classes.cont].join(" ")}>
          <div className={classes.teamRow}>
            <div className={classes.teamRowLeft}>
              <img src={img1} alt="" />
            </div>
            <div className={classes.teamRowRight}>
              <h2 className={[classes.teamRowRightTitle, "font-48"].join(" ")}>
                <Trans>{t('our_team_title')}</Trans>
              </h2>
              <p className={[classes.teamRowRightText, "font-20"].join(" ")}>
                <Trans>our_team_text_01</Trans>
              </p>
              <p className={[classes.teamRowRightText, "font-20"].join(" ")}>
                <Trans>our_team_text_02</Trans>
              </p>
              <p className={[classes.teamRowRightText, "font-20"].join(" ")}>
                <Trans>our_team_text_03</Trans>
              </p>
              <p className={[classes.teamRowRightText, "font-20"].join(" ")}>
                <Trans>our_team_text_04</Trans>
              </p>
              <p className={[classes.teamRowRightText, "font-20"].join(" ")}>
                <Trans>our_team_text_05</Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;

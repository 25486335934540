import React from "react";
import { Trans } from "react-i18next";
import classes from "./First.module.scss";
import { useTranslation } from "react-i18next";

const First = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.first}>
      <div className={classes.firstBody}>
        <div className="container">
          <h1 className={[classes.firstTitle, "font-80"].join(" ")}>
            <Trans>{t('tools_banner_title')}</Trans>
          </h1>
          <p className={[classes.firstSubtitle, "font-24"].join(" ")}>
            <Trans>tools_banner_text</Trans>
          </p>
        </div>
      </div>
    </div>
  );
};

export default First;

import React from "react";
import classes from '../Banner.module.scss';
import Button from "../../../GlobalComponents/Button/Button";
import coin_01 from '../../../../assets/img/MainPage/Banner/slide_03/coin_01.png';
import coin_02 from '../../../../assets/img/MainPage/Banner/slide_03/coin_02.png';
import coin_03 from '../../../../assets/img/MainPage/Banner/slide_03/coin_03.png';
import coin_04 from '../../../../assets/img/MainPage/Banner/slide_03/coin_04.png';
import { useTranslation } from "react-i18next";

const ThirdSlide = ({ isActive = false }) => {
    const { t } = useTranslation();
    
    return (
        <div className={[classes.slide, classes.thirdSlide].join(' ')}>
            <div className="container">
                 <div className={classes.content}>
                    <h2 className={[classes.title, 'font-64-banner'].join(' ')}>
                        {t('main_banner_slide_title_03')}
                    </h2>
                    <h3 className={[classes.subtitle, classes.subtitle_03, 'font-24'].join(' ')}>
                        {t('main_banner_slide_subtitle_03')}
                    </h3>
                    <div className={classes.btn}>
                        <Button>{t('btn_here')}</Button>
                    </div>
                    
                    <img className={`${classes.coin} ${classes.coin_01} ${isActive && classes.coinActive}`} src={coin_01} alt=''/>
                    <img className={`${classes.coin} ${classes.coin_02} ${isActive && classes.coinActive}`} src={coin_02} alt=''/>
                    <img className={`${classes.coin} ${classes.coin_03} ${isActive && classes.coinActive}`} src={coin_03} alt=''/>
                    <img className={`${classes.coin} ${classes.coin_04} ${isActive && classes.coinActive}`} src={coin_04} alt=''/>
                </div>
            </div>
        </div>
    );
};

export default ThirdSlide;

// main page
import box_01 from '../assets/img/MainPage/Banner/slide_01/box_01.png';
import box_02 from '../assets/img/MainPage/Banner/slide_01/box_02.png';
import box_03 from '../assets/img/MainPage/Banner/slide_01/box_03.png';
import box_04 from '../assets/img/MainPage/Banner/slide_01/box_04.png';
import copter_01 from '../assets/img/MainPage/Banner/slide_02/copter_01.png';
import copter_02 from '../assets/img/MainPage/Banner/slide_02/copter_02.png';
import copter_03 from '../assets/img/MainPage/Banner/slide_02/copter_03.png';
import coin_01 from '../assets/img/MainPage/Banner/slide_03/coin_01.png';
import coin_02 from '../assets/img/MainPage/Banner/slide_03/coin_02.png';
import coin_03 from '../assets/img/MainPage/Banner/slide_03/coin_03.png';
import coin_04 from '../assets/img/MainPage/Banner/slide_03/coin_04.png';
import icon_01 from '../assets/img/MainPage/UnderBanner/icon_01.svg';
import icon_02 from '../assets/img/MainPage/UnderBanner/icon_02.svg';
import icon_03 from '../assets/img/MainPage/UnderBanner/icon_03.svg';
import icon_04 from '../assets/img/MainPage/UnderBanner/icon_04.svg';

export const images = [
    box_01,
    box_02,
    box_03,
    box_04,
    copter_01,
    copter_02,
    copter_03,
    coin_01,
    coin_02,
    coin_03,
    coin_04,
    icon_01,
    icon_02,
    icon_03,
    icon_04,
];
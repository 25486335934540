import React from "react";
import { Trans } from "react-i18next";
import classes from "./Four.module.scss";
import { useTranslation } from "react-i18next";

const Four = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.four}>
      <div className={["container", classes.cont].join(" ")}>
        <div className={classes.fourBody}>
          <div className={classes.fourWrap}>
            <p className={[classes.fourOverTitle, "font-40"].join(" ")}>
              <Trans>{t('our_main_goal_title')}</Trans>
            </p>
            <h2 className={[classes.fourTitle, "font-64"].join(" ")}>
              <Trans>our_main_goal_subtitle</Trans>
            </h2>
            <p className={[classes.fourSubtitle, "font-24"].join(" ")}>
              <Trans>our_main_goal_text</Trans>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;

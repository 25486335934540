import React, { useEffect, useState, useRef } from "react";
import classes from "./Header.module.scss";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import i18n from "i18next";
import Cookies from "js-cookie";
import { headerRoutes } from "../../../router";
import Button from "../Button/Button";
import logo from '../../../assets/img/logo.svg';

const languages = [
  { value: "ru", label: "RU" },
  { value: "en", label: "EN" },
  { value: "pl", label: "PL" },
  { value: "de", label: "DE" },
  { value: "it", label: "IT" },
  { value: "es", label: "ES" },
  { value: "fr", label: "FR" },
  { value: "da", label: "DA" },
  { value: "sr", label: "SR" },
  { value: "tr", label: "TR" },
  { value: "cs", label: "CS" },
  { value: "ro", label: "RO" },
];

const Header = () => {
  const headerRef = useRef(null);
  const { t } = useTranslation();
  const [ burgerOpened, setBurgerOpened ] = useState(false);
  const [ currentLang, setCurrentLang ] = useState(Cookies.get("i18next"));
  const { pathname } = useLocation();

  useEffect(() => {
    setCurrentLang(Cookies.get("i18next"));
  }, []);
  useEffect(() => {
    setBurgerOpened(false);
  }, [ pathname ]);
  window.addEventListener("resize", (event) => {
    setBurgerOpened(false);
  });

  return (
    <div ref={headerRef} className={`${classes.header} ${burgerOpened && classes.headerActive}`}>
      <div className={["container", classes.container].join(' ')}>
        <div className={classes.headerRow}>
          <div className={classes.headerRowLogo}>
            <Link to='/'>
              <img className={classes.logo} src={logo} alt='' />
            </Link>
          </div>
          <div className={classes.headerRowLinks}>
            {headerRoutes.map((route) =>
              <Link 
                key={route.id}
                to={route.path}
                className={`${route.path === pathname && classes.linkActive}`}  
              > {t(route.text)} </Link>
            )}
          </div>
          <div className={classes.headerRowRight}>
            <div className={classes.langs}>
              <Dropdown
                options={languages}
                onChange={(e) => i18n.changeLanguage(e.value)}
                value={currentLang}
                placeholder="EN"
              />
            </div>
            <a 
              href={`${process.env.REACT_APP_CABINET_LINK}/login`}
              className={[classes.login, 'no-select'].join(' ')}
            >
              {t('header_login')}
            </a>
            <div 
              onClick={() => setBurgerOpened((value) => !value)}
              className={`${classes.burger} ${burgerOpened && classes.burgerActive}`}
            ></div>
          </div>
        </div>
      </div>
      <div className={`${classes.mobileMenu} ${burgerOpened && classes.mobileMenuActive}`}>
        <div className="container">
          {headerRoutes.map((route) =>
            <Link
              key={route.id}
              to={route.path}
              className={`${route.path === pathname && classes.linkActive} ${classes.aLink}`}  
            > {t(route.text)} </Link>
          )}
          <div className={classes.btn}>
            <Button login>
              {t('header_login')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

import React from "react";
import classes from './Partners.module.scss';
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";
import icon_01 from '../../../assets/img/MainPage/Partners/icon_01.png';
import icon_02 from '../../../assets/img/MainPage/Partners/icon_02.png';
import icon_03 from '../../../assets/img/MainPage/Partners/icon_03.png';
import icon_04 from '../../../assets/img/MainPage/Partners/icon_04.png';
import icon_05 from '../../../assets/img/MainPage/Partners/icon_05.png';
import icon_06 from '../../../assets/img/MainPage/Partners/icon_06.png';
import icon_07 from '../../../assets/img/MainPage/Partners/icon_07.png';
import icon_08 from '../../../assets/img/MainPage/Partners/icon_08.png';
import icon_09 from '../../../assets/img/MainPage/Partners/icon_09.png';

const cards = [
    {
        icon: icon_01,
    },
    {
        icon: icon_02,
    },
    {
        icon: icon_03,
    },
    {
        icon: icon_04,
    },
    {
        icon: icon_05,
    },
    {
        icon: icon_06,
    },
    {
        icon: icon_07,
    },
    {
        icon: icon_08,
    },
    {
        icon: icon_09,
    },
];

const Partners = () => {
    const { t } = useTranslation();
    return (
        <section className="mt ovf-hidden">
            <div className={classes.partners}>
                <div className="container">
                    <h2 className={`${classes.title} font-64`}>
                        {t('home_partners_title')}
                    </h2>
                </div>
                <div className={classes.cards}>
                    <div className={`${classes.marquee} ${classes.marquee_02}`}>
                        <Marquee autoFill>
                            <div className={classes.content}>
                                {cards.map((item, index) =>
                                    <img className={classes.icon} key={index} src={item.icon} alt='' />
                                )}
                            </div>
                        </Marquee>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Partners;

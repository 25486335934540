import React from "react";
import classes from "./Second.module.scss";
import img1 from "../../../assets/img/tools/2/1.svg";
import img2 from "../../../assets/img/tools/2/2.svg";
import img3 from "../../../assets/img/tools/2/3.svg";
import img4 from "../../../assets/img/tools/2/4.svg";
import img5 from "../../../assets/img/tools/2/5.svg";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const Second = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.second}>
      <div className={classes.secondBody}>
        <div className="container">
          <div className={[classes.secondTitle, "font-32"].join(" ")}>
            <Trans>{t('these_tools_title')}</Trans>
          </div>
          <div className={classes.secondRow}>
            <div className={classes.secondRowColumn}>
              <div className={classes.secondRowColumnImg}>
                <img src={img1} alt="" />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                <Trans>these_tools_li_01</Trans>
              </p>
            </div>
            <div className={classes.secondRowColumn}>
              <div className={classes.secondRowColumnImg}>
                <img src={img2} alt="" />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                <Trans>these_tools_li_02</Trans>
              </p>
            </div>
            <div className={classes.secondRowColumn}>
              <div className={classes.secondRowColumnImg}>
                <img src={img3} alt="" />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                <Trans>these_tools_li_03</Trans>
              </p>
            </div>
            <div className={classes.secondRowColumn}>
              <div className={classes.secondRowColumnImg}>
                <img src={img4} alt="" />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                <Trans>these_tools_li_04</Trans>
              </p>
            </div>
            <div className={classes.secondRowColumn}>
              <div className={classes.secondRowColumnImg}>
                <img src={img5} alt="" />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                <Trans>these_tools_li_05</Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;

import React from "react";
import classes from "./Three.module.scss";
import img1 from "../../../assets/img/tools/3/1.svg";
import img2 from "../../../assets/img/tools/3/2.svg";
import img3 from "../../../assets/img/tools/3/3.svg";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const Three = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.three}>
      <div className={classes.threeBody}>
        <div className={["container", classes.cont].join(" ")}>
          <h2 className={[classes.threeTitle, "font-64"].join(" ")}>
            <Trans>{t('all_tools_title')}</Trans>
          </h2>
          <div className={classes.threeWrapper}>
            <div className={classes.threeRow}>
              <div className={classes.threeRowColumn}>
                <div className={classes.threeRowColumnItem}>
                  <div className={classes.threeRowColumnImg}>
                    <img src={img1} alt="" />
                  </div>
                  <h3
                    className={[classes.threeRowColumnTitle, "font-36"].join(
                      " "
                    )}
                  >
                    <Trans>all_tools_card_title_01</Trans>
                  </h3>
                  <p
                    className={[classes.threeRowColumnSubtitle, "font-20"].join(
                      " "
                    )}
                  >
                    <Trans>all_tools_card_text_01</Trans>
                  </p>
                </div>
              </div>
              <div className={classes.threeRowColumn}>
                <div className={classes.threeRowColumnItem}>
                  <div className={classes.threeRowColumnImg}>
                    <img src={img2} alt="" />
                  </div>
                  <h3
                    className={[classes.threeRowColumnTitle, "font-36"].join(
                      " "
                    )}
                  >
                    <Trans>all_tools_card_title_02</Trans>
                  </h3>
                  <p
                    className={[classes.threeRowColumnSubtitle, "font-20"].join(
                      " "
                    )}
                  >
                    <Trans>all_tools_card_text_02</Trans>
                  </p>
                </div>
              </div>
              <div className={classes.threeRowColumn}>
                <div className={classes.threeRowColumnItem}>
                  <div className={classes.threeRowColumnImg}>
                    <img src={img3} alt="" />
                  </div>
                  <h3
                    className={[classes.threeRowColumnTitle, "font-36"].join(
                      " "
                    )}
                  >
                    <Trans>all_tools_card_title_03</Trans>
                  </h3>
                  <p
                    className={[classes.threeRowColumnSubtitle, "font-20"].join(
                      " "
                    )}
                  >
                    <Trans>all_tools_card_text_03</Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;

import React, { useEffect } from "react";
import classes from "./FaqPage.module.scss";
import Most from "../../components/FaqPageComponents/Most/Most";
const FaqPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.faq}>
      <Most />
    </div>
  );
};

export default FaqPage;

import React, { useState } from 'react';
import classes from "./AccountTypes.module.scss";
import { useTranslation } from 'react-i18next';
import svg from "../../../assets/img/Account/check.svg";

const AccountTypes = () => {
    const { t } = useTranslation();

    const [tabs, setTabs] = useState([
        {
            id: 1, active: true, btn: "Standard", items: [
                {
                    left: "acc_td_1", right: "acc_td_19"
                },
                {
                    left: "acc_td_2", right: "acc_td_20"
                },
                {
                    left: "acc_td_3", right: "acc_td_21"
                },
                {
                    left: "acc_td_4", right: "acc_td_22"
                },
                {
                    left: "acc_td_5", right: "acc_td_23"
                },
                {
                    left: "acc_td_6", right: "250$"
                },
                {
                    left: "acc_td_7", right: "acc_td_25"
                },
                {
                    left: "acc_td_8", right: svg
                },
                {
                    left: "acc_td_9", right: "1:2000"
                },
                {
                    left: "acc_td_10", right: svg
                },
                {
                    left: "acc_td_11", right: "0.01"
                },
                {
                    left: "acc_td_12", right: "acc_td_26"
                },
                {
                    left: "acc_td_13", right: "150"
                },
                {
                    left: "acc_td_14", right: "50%"
                },
                {
                    left: "acc_td_15", right: "20%"
                },
                {
                    left: "acc_td_16", right: svg
                },
                {
                    left: "acc_td_17", right: svg
                },
                {
                    left: "acc_td_18", right: "USD"
                },

            ]
        },
        {
            id: 2, active: false, btn: "Premium", items: [
                { left: "acc_td_1", right: "acc_td_19" },
                { left: "acc_td_2", right: "acc_td_20" },
                { left: "acc_td_3", right: "acc_td_21" },
                { left: "acc_td_4", right: "acc_td_27" },
                { left: "acc_td_5", right: "acc_td_28" },
                { left: "acc_td_6", right: "5.000$" },
                { left: "acc_td_7", right: "acc_td_25" },
                { left: "acc_td_8", right: svg },
                { left: "acc_td_9", right: "1:2000" },
                { left: "acc_td_10", right: svg },
                { left: "acc_td_11", right: "0.01" },
                { left: "acc_td_13", right: "acc_td_29" },
                { left: "acc_td_14", right: "500" },
                { left: "acc_td_15", right: "50%" },
                { left: "acc_td_16", right: "20%" },
                { left: "acc_td_17", right: svg },
                { left: "acc_td_18", right: svg },
                { left: "acc_td_19", right: "USD, EUR, NGN, JPY" },
            ]
        },
        {
            id: 3, active: false, btn: "PRO", items: [
                { left: "acc_td_1", right: "acc_td_19" },
                { left: "acc_td_2", right: "acc_td_20" },
                { left: "acc_td_3", right: "acc_td_30" },
                { left: "acc_td_4", right: "acc_td_27" },
                { left: "acc_td_5", right: "acc_td_31" },
                { left: "acc_td_6", right: "100.000$" },
                { left: "acc_td_7", right: "acc_td_25" },
                { left: "acc_td_8", right: svg },
                { left: "acc_td_9", right: "1:2000" },
                { left: "acc_td_10", right: svg },
                { left: "acc_td_11", right: "0.01" },
                { left: "acc_td_12", right: "acc_td_29" },
                { left: "acc_td_13", right: "500" },
                { left: "acc_td_14", right: "50%" },
                { left: "acc_td_15", right: "20%" },
                { left: "acc_td_16", right: svg },
                { left: "acc_td_17", right: svg },
                { left: "acc_td_18", right: "USD, EUR, NGN, JPY" },
            ]
        },
        {
            id: 4, active: false, btn: "Zero", items: [
                { left: "acc_td_1", right: "acc_td_19" },
                { left: "acc_td_2", right: "acc_td_20" },
                { left: "acc_td_3", right: "acc_td_32" },
                { left: "acc_td_4", right: "acc_td_27" },
                { left: "acc_td_5", right: "acc_td_31" },
                { left: "acc_td_6", right: "500.000$" },
                { left: "acc_td_7", right: "acc_td_33" },
                { left: "acc_td_8", right: svg },
                { left: "acc_td_9", right: "1:2000" },
                { left: "acc_td_10", right: svg },
                { left: "acc_td_11", right: "0.01" },
                { left: "acc_td_12", right: "acc_td_29" },
                { left: "acc_td_13", right: "500" },
                { left: "acc_td_14", right: "50%" },
                { left: "acc_td_15", right: "20%" },
                { left: "acc_td_16", right: svg },
                { left: "acc_td_17", right: svg },
                { left: "acc_td_18", right: "USD, EUR, NGN, JPY" },
            ]
        },

    ]);

    return (
        <div className={[classes.tabs, "mt"].join(" ")}>
            <div className={classes.tabsBody}>
                <div className="container">
                    <h2 className={`${classes.ttl} font-64`}>
                        {t('accs_title')}
                    </h2>
                    <div className={classes.tabsWrap}>
                        <div className={classes.tabsBtns}>
                            {tabs.map((btn, index) =>
                                <p
                                    onClick={() => {
                                        setTabs(tabs.map((sub) => {
                                            if (btn.id === sub.id) {
                                                return { ...sub, active: true }
                                            } else {
                                                return { ...sub, active: false }
                                            }
                                        }))
                                    }}
                                    key={index}
                                    className={btn.active ? [classes.tabsBtnsBtn, classes.active, "font-16"].join(" ") : [classes.tabsBtnsBtn, "font-16"].join(" ")}
                                >
                                    {t(btn.btn)}
                                </p>
                            )}
                        </div>
                        <div className={classes.tabsTableWrap}>
                            {tabs.map((i, index) => i.active &&
                                <div key={index}>
                                    {i.items.map((subI, subIndex) =>
                                        subI.right.includes("svg") ?
                                            <div key={subIndex} className={classes.tabsRow}>
                                                <p className={[classes.tabsRowLeft, "font-16"].join(" ")}>
                                                    {t(subI.left)}
                                                </p>
                                                <div className={classes.tabsRowRight}>
                                                    <img src={subI.right} alt="" />
                                                </div>
                                            </div>
                                            :
                                            <div key={subIndex} className={[classes.tabsRow, "font-16"].join(" ")}>
                                                <p className={classes.tabsRowLeft}>
                                                    {t(subI.left)}
                                                </p>
                                                <p className={classes.tabsRowRight}>
                                                    {t(subI.right)}
                                                </p>
                                            </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountTypes;

import React, { useEffect } from "react";
import Team from "../../components/AboutPageComponents/Team/Team";
import Welcome from "../../components/AboutPageComponents/Welcome/Welcome";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Welcome />
      <Team />
    </div>
  );
};

export default AboutPage;

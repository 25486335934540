import React, { useEffect } from "react";
import Articles from "../../components/EducationPage/Articles/Articles";
import StayIn from "../../components/EducationPage/StayIn/StayIn";

const EducationPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="ovf-hidden">
            <StayIn />
            <Articles />
        </div>
    );
};

export default EducationPage;

import React from "react";
import classes from '../Banner.module.scss';
import Button from "../../../GlobalComponents/Button/Button";
import copter_01 from '../../../../assets/img/MainPage/Banner/slide_02/copter_01.png';
import copter_02 from '../../../../assets/img/MainPage/Banner/slide_02/copter_02.png';
import copter_03 from '../../../../assets/img/MainPage/Banner/slide_02/copter_03.png';
import { useTranslation } from "react-i18next";

const cards = [
    'main_banner_slide_02_li_01',
    'main_banner_slide_02_li_02',
    'main_banner_slide_02_li_03',
    'main_banner_slide_02_li_04',
];

const SecondSlide = ({ isActive = false }) => {
    const { t } = useTranslation();

    return (
        <div className={[classes.slide, classes.secondSlide].join(' ')}>
            <div className="container">
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-64-banner'].join(' ')}>
                        {t('main_banner_slide_title_02')}
                    </h2>
                    <h3 className={[classes.subtitle, 'font-24'].join(' ')}>
                        {t('main_banner_slide_subtitle_02')}
                    </h3>
                    <div className={classes.cards}>
                        {cards.map((card, index) =>
                            <div key={index} className={classes.card}>
                                <p>{t(card)}</p>
                            </div>
                        )}
                    </div>
                    <div className={classes.btn}>
                        <Button>{t('btn_get_more_info')}</Button>
                    </div>
                    
                    <img className={`${classes.copter_01} ${isActive && classes.copterActive}`} src={copter_01} alt=''/>
                    <img className={`${classes.copter_02} ${isActive && classes.copterActive}`} src={copter_02} alt=''/>
                    <img className={`${classes.copter_03} ${isActive && classes.copterActive}`} src={copter_03} alt=''/>
                </div>
            </div>
        </div>
    );
};

export default SecondSlide;

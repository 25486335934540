import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import classes from './ArticlePage.module.scss';
import image_01 from '../../../assets/img/EducationPage/five_best_large.png';
import image_02 from '../../../assets/img/EducationPage/market_trends_large.png';
import image_03 from '../../../assets/img/EducationPage/how_to_find_large.png';
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const articles = {
    162: {
        image: image_01,
        title: 'article_01_title',
        content: [
            'article_01_text_01',
            [
                'article_01_li_01',
                'article_01_li_02',
                'article_01_li_03',
                'article_01_li_04',
                'article_01_li_05',
            ],
            'article_01_text_02',
        ],
    },
    213: {
        image: image_02,
        title: 'article_02_title',
        content: [
            'article_02_text_01',
            'article_02_text_02',
            'article_02_text_03',
            'article_02_text_04',
            'article_02_text_05',
        ],
    },
    215: {
        image: image_03,
        title: 'article_03_title',
        content: [
            'article_03_text_01',
            'article_03_text_02',
            [
                'article_02_li_01',
                'article_02_li_02',
                'article_02_li_03',
                'article_02_li_04',
                'article_02_li_05',
            ],
            'article_03_text_03',
        ],
    },
};

const ArticlePage = () => {
    const { t } = useTranslation();
    const [ article, setArticle ] = useState({});
    const [ wrongId, setWrongId ] = useState(false);
    const [ showArticle, setShowArticle ] = useState(false);
    const { id } = useParams();
    useEffect(() => {
        if (Object.keys(article).length > 0) {
            setShowArticle(true);
        }
    }, [article])
    useEffect(() => {
        window.scrollTo(0, 0);
        if (articles[id] !== undefined) {
            setArticle(articles[id]);
            setWrongId(false);
            return
        }
        setWrongId(true);
    }, [ id ]);
    return (
        <section className="container">
            <div className={classes.articlePage}>
                {wrongId ?
                    <div className={[classes.whongId, 'font-20'].join(' ')}>
                        Wrong article id
                    </div>
                    :
                    showArticle &&
                        <div className={classes.content}>
                            <div className={classes.header}>
                                <img src={article.image} alt={article.title}/>
                            </div>
                            <h1 className={classes.title}>
                                <Trans>
                                    {article.title}
                                </Trans>
                            </h1>
                            <div className={[classes.text, 'font-20'].join(' ')}>
                                {article.content.map((item, itemIndex) => {
                                    if (typeof(item) === 'string') {
                                        return (
                                            <p key={itemIndex}>
                                                <Trans>
                                                    {item}
                                                </Trans>
                                            </p>
                                        )
                                    }
                                    return (
                                        <ul key={itemIndex}>
                                            {item.map((listItem, listItemIndex) =>
                                                <li key={listItemIndex}>
                                                    <Trans>
                                                        {listItem}
                                                    </Trans>
                                                </li>
                                            )}
                                        </ul>
                                    )
                                })}
                            </div>
                            <div className={classes.buttons}>
                                <Link to={`/education`} className={classes.btnBack}>
                                    <span>
                                        <Trans>{t('btn_back')}</Trans>
                                    </span>
                                </Link>
                                <Button>
                                    <Trans>btn_start_trading</Trans>
                                </Button>
                            </div>
                        </div>
                    
                }

                <div className={classes.other}>
                    {Object.keys(articles).map((key, index) =>
                        key !== id &&
                                <div key={index} className={classes.otherItem}>
                                    <img src={articles[key].image} alt={articles[key].title}/>
                                    <h4 className={classes.otherItemTitle}>
                                        <Trans>
                                            {articles[key].title}
                                        </Trans>
                                    </h4>
                                    <Link className={[classes.otherItemLink, 'font-20'].join(' ')} to={`/education/${key}`}>
                                        <Trans>btn_learn_more</Trans>
                                    </Link>
                                </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default ArticlePage;

import React, { useEffect } from "react";
import Banner from "../../components/MainPage/Banner/Banner";
import UnderBanner from "../../components/MainPage/UnderBanner/UnderBanner";
import WhyChoose from "../../components/MainPage/WhyChoose/WhyChoose";
import TradingInstruments from "../../components/MainPage/TradingInstruments/TradingInstruments";
import Partners from "../../components/MainPage/Partners/Partners";
import KeysPlayer from "../../components/MainPage/KeysPlayer/KeysPlayer";

const MainPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="">
            <div className="ovf-hidden">
                <Banner />
                <UnderBanner />
                <TradingInstruments />
                <WhyChoose />
            </div>
            <Partners />
            <KeysPlayer />
        </div>
    );
};

export default MainPage;

import React from "react";
import classes from '../Banner.module.scss';
import Button from '../../../GlobalComponents/Button/Button';
import box_01 from '../../../../assets/img/MainPage/Banner/slide_01/box_01.png';
import box_02 from '../../../../assets/img/MainPage/Banner/slide_01/box_02.png';
import box_03 from '../../../../assets/img/MainPage/Banner/slide_01/box_03.png';
import box_04 from '../../../../assets/img/MainPage/Banner/slide_01/box_04.png';
import { useTranslation } from "react-i18next";

const FirstSlide = ({ isActive = false }) => {
    const { t } = useTranslation();
    return (
        <div className={[classes.slide, classes.firstSlide].join(' ')}>
            <div className="container">
                <div className={classes.content}>
                    <h1 className={[classes.title, 'font-64'].join(' ')}>
                        {t('main_banner_slide_title_01')}
                    </h1>
                    <h3 className={[classes.subtitle, 'font-24'].join(' ')}>
                        {t('main_banner_slide_subtitle_01')}
                    </h3>
                    <p className={[classes.text, 'font-20'].join(' ')}>
                        {t('main_banner_slide_text_01')}
                    </p>
                    <div className={classes.btn}>
                        <Button>{t('btn_start_trading')}</Button>
                    </div>

                    <img className={`${classes.box} ${classes.box_01} ${isActive && classes.boxActive}`} src={box_01} alt=''/>
                    <img className={`${classes.box} ${classes.box_02} ${isActive && classes.boxActive}`} src={box_02} alt=''/>
                    <img className={`${classes.box} ${classes.box_03} ${isActive && classes.boxActive}`} src={box_03} alt=''/>
                    <img className={`${classes.box} ${classes.box_04} ${isActive && classes.boxActive}`} src={box_04} alt=''/>
                </div>
            </div>
        </div>
    );
};

export default FirstSlide;

import MainPage from '../pages/MainPage/MainPage';
import ToolsPage from '../pages/ToolsPage/ToolsPage';
import AccountPage from '../pages/AccountPage/AccountPage';
import EducationPage from '../pages/EducationPage/EducationPage';
import EducationPageItem from '../pages/EducationPage/EducationPageItem';
import FaqPage from '../pages/FaqPage/FaqPage';
import AboutPage from '../pages/AboutPage/AboutPage';

export const routes = [
    { path: '*', element: MainPage },
    { path: '/tools', element: ToolsPage },
    { path: '/account', element: AccountPage },
    { path: '/education', element: EducationPage },
    { path: '/education/:id', element: EducationPageItem },
    { path: '/faq', element: FaqPage },
    { path: '/about', element: AboutPage },
];

export const headerRoutes = [
    { id: 1, path: '/tools', text: "header_tools_platforms" },
    { id: 2, path: '/account', text: "header_account_types" },
    { id: 3, path: '/education', text: "header_education" },
    { id: 4, path: '/faq', text: "header_faq" },
    { id: 5, path: '/about', text: "header_about_us" },
];

import React from "react";
import classes from './Articles.module.scss';
import image_01 from '../../../assets/img/EducationPage/five_best.png';
import image_02 from '../../../assets/img/EducationPage/market_trends.png';
import image_03 from '../../../assets/img/EducationPage/how_to_find.png';
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const articles = [
    {
        id: 162,
        image: image_01,
        title: 'article_01_title',
        text: 'article_01_short_text',
    },
    {
        id: 213,
        image: image_02,
        title: 'article_02_title',
        text: 'article_02_short_text',
    },
    {
        id: 215,
        image: image_03,
        title: 'article_03_title',
        text: 'article_03_short_text',
    },
];

const Articles = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.articles}>
                {articles.map((article) =>
                    <div className={classes.article} key={article.id}>
                        <img className={classes.articleImage} src={article.image} alt={article.title}/>
                        <div className={classes.articleContent}>
                            <div className={classes.articleTitle}>
                                <Trans>
                                    {t(article.title)}
                                </Trans>
                            </div>
                            <div className={[classes.articleText, 'font-20'].join(' ')}>
                                <Trans>
                                    {t(article.text)}
                                </Trans>
                            </div>
                            <Link to={`/education/${article.id}`} className={classes.articleBtn}>
                                <span>
                                    <Trans>{t('btn_learn_more')}</Trans>
                                </span>
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default Articles;

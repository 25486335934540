import React from "react";
import classes from './UnderBanner.module.scss';
import icon_01 from '../../../assets/img/MainPage/UnderBanner/icon_01.svg';
import icon_02 from '../../../assets/img/MainPage/UnderBanner/icon_02.svg';
import icon_03 from '../../../assets/img/MainPage/UnderBanner/icon_03.svg';
import icon_04 from '../../../assets/img/MainPage/UnderBanner/icon_04.svg';
import { useTranslation } from "react-i18next";

const cards = [
    {
        icon: icon_01,
        text: 'underbanner_li_01',
    },
    {
        icon: icon_02,
        text: 'underbanner_li_02',
    },
    {
        icon: icon_03,
        text: 'underbanner_li_03',
    },
    {
        icon: icon_04,
        text: 'underbanner_li_04',
    },
];

const UnderBanner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.underBanner}>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div className={classes.card} key={index}>
                            <img className={classes.icon} src={card.icon} alt={card.text}/>
                            <p className={classes.text}>{t(card.text)}</p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default UnderBanner;
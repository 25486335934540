import React from "react";
import classes from "./Footer.module.scss";
import logo from "../../../assets/img/logo_white.svg";
import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <div className={classes.footer}>
      <div className={`container ${classes.cont}`}>
        <div className={classes.footerBody}>
          <div className={classes.footerRow}>
            <div className={classes.footerRowLeft}>
              <img src={logo} alt="" />
            </div>
            <div className={classes.footerRowRight}>
              <div className={classes.footerRowRightTop}>
                <div className={`${classes.footerRowRightTopLeft} font-40`}>
                  {t('footer_contact_us')}
                </div>
                <div className={`${classes.footerRowRightTopRight} font-16-f`}>
                  <a href="tel:+441212696128" className={classes.footerRowRightTopRightPhone}>
                    +441212696128
                  </a>
                  <a href={`mailto:support@spx-trade.co`} className={classes.footerRowRightTopRightEmail}>
                    support@spx-trade.co
                  </a>
                </div>
              </div>
              <div className={classes.footerRowRightCenter}>
                <div className={`${classes.footerRowRightCenterLeft} font-16-f`}>
                  <a
                    href="/docs/TERMS_&_CONDITIONS.pdf"
                    target="_blank"
                    className={classes.footerRowRightCenterLeftItem}
                  >
                    {t('footer_terms_conditions')}
                  </a>
                  <a
                    href="/docs/PRIVACY_POLICY.pdf"
                    target="_blank"
                    className={classes.footerRowRightCenterLeftItem}
                  >
                    {t('footer_privacy_policy')}
                  </a>
                  <a
                    href="/docs/AML_POLICY.pdf"
                    target="_blank"
                    className={classes.footerRowRightCenterLeftItem}
                  >
                    {t('footer_aml_policy')}
                  </a>
                </div>
                <div className={classes.footerRowRightCenterCenter}>
                  <div className={classes.footerRowRightCenterCenterTitle}>
                    {t('footer_desclaimer')}
                  </div>
                  <div className={classes.footerRowRightCenterCenterText}>
                    {t('footer_desclaimer_text')}
                  </div>
                </div>
                <div className={classes.footerRowRightCenterRight}>
                  <div className={classes.footerRowRightCenterRightTitle}>
                    {t('footer_risk')}
                  </div>
                  <div className={classes.footerRowRightCenterRightText}>
                    {t('footer_risk_text_01')}
                  </div>
                  <div className={classes.footerRowRightCenterRightText}>
                    <Trans>
                      {t('footer_risk_text_02')}
                    </Trans>
                  </div>
                </div>
              </div>
              <div className={classes.footerRowRightBottom}>
                <div className={`${classes.footerRowRightCopyright} font-16`}>
                  {t('footer_rights')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

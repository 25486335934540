import React, { useState, useEffect } from "react";
import classes from './Banner.module.scss';
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Marquee from "react-fast-marquee";
import FirstSlide from "./Slides/FirstSlide";
import SecondSlide from "./Slides/SecondSlide";
import ThirdSlide from "./Slides/ThirdSlide";
import './Swiper.scss';

const TrendingCoins = (currency) =>
    `https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&order=gecko_desc&per_page=10&page=1&sparkline=false&price_change_percentage=24h`;

const Banner = () => {
    const [currentSlide, setCurrentSlide] = useState(1);
    const [coins, setCoins] = useState([]);
    const [isError, setIsError] = useState(false);
    const parseCoin = (symbol, price, percent) => `${symbol.toUpperCase()} $${price.toFixed(2)} (${percent.toFixed(2)}%)`;
    const currency = "usd";

    const fetchTradingCoins = async () => {
        try {
            const { data } = await axios.get(TrendingCoins(currency))
            if (isError === false) {
                setCoins(data.map((item) => parseCoin(item.symbol, item.current_price, item.price_change_percentage_24h)));
            }
        } catch (error) {
            setIsError(true);
            console.log("Request error in fetchTradingCoins()", error);
        }
    }

    useEffect(() => {
        fetchTradingCoins();
        // eslint-disable-next-line
    }, [currency]);
    
    return (
        <section className={classes.banner} id="mainPageBanner">
            <div className={`${classes.marquee}`}>
                <Marquee autoFill pauseOnHover speed={90}>
                    {
                        coins.map((text, key) => {
                            return (
                                <h4 className={[classes.line, 'no-select'].join(' ')} key={key}>
                                    {text} &nbsp;&nbsp;
                                </h4>
                            )
                        })
                    }
                </Marquee>
            </div>

            <div className={`${classes.bannerBg} ${classes.bannerBgFirst} ${currentSlide === 1 && classes.activeBg}`}></div>
            <div className={`${classes.bannerBg} ${classes.bannerBgSecond} ${currentSlide === 2 && classes.activeBg}`}></div>
            <div className={`${classes.bannerBg} ${classes.bannerBgThird} ${currentSlide === 3 && classes.activeBg}`}></div>

            <Carousel
                className={classes.carousel}
                autoPlay={false}
                onChange={(newIndex) => setCurrentSlide(newIndex + 1)}
                selectedItem={currentSlide - 1}
                showThumbs={false}
                showArrows={document.documentElement.clientWidth >= 1220 ? true : false}
                swipeable={true}
                showStatus={false}
                showIndicators={false}
            >
                <div>
                    <FirstSlide isActive={currentSlide === 1 && true} />
                </div>
                <div>
                    <SecondSlide isActive={currentSlide === 2 && true} />
                </div>
                <div>
                    <ThirdSlide isActive={currentSlide === 3 && true} />
                </div>
            </Carousel>
            <div className={classes.indicators}>
                <div
                    className={`${classes.indicator} ${currentSlide === 1 && classes.indicatorCurrent}`}
                    onClick={() => setCurrentSlide(1)}
                ></div>
                <div
                    className={`${classes.indicator} ${currentSlide === 2 && classes.indicatorCurrent}`}
                    onClick={() => setCurrentSlide(2)}
                ></div>
                <div
                    className={`${classes.indicator} ${currentSlide === 3 && classes.indicatorCurrent}`}
                    onClick={() => setCurrentSlide(3)}
                ></div>
            </div>
        </section>
    );
};

export default Banner;

import React, { useState } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Most.module.scss";
import { Trans, useTranslation } from "react-i18next";

const Most = () => {
  const { t } = useTranslation();
  const [spoilers, setSpoilers] = useState([
    {
      id: 1,
      title: "faq_01_title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: false,
          listNumbers: [
            `faq_01_li_01`,
            `faq_01_li_02`,
            `faq_01_li_03`,
            `faq_01_li_04`,
            `faq_01_li_05`,
          ],
          text: "",
        },
      ],
    },
    {
      id: 2,
      title: "faq_02_title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `faq_02_text`,
        },
      ],
    },
    {
      id: 3,
      title: "faq_03_title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `faq_03_text`,
        },
      ],
    },
    {
      id: 4,
      title:
        "faq_04_title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `faq_04_text`,
        },
      ],
    },
    {
      id: 5,
      title: "faq_05_title",
      active: false,
      contents: [
        {
          id: 1,
          isStart: true,
          text: `faq_05_text`,
          listNumbers: [
            `faq_05_li_01`,
            `faq_05_li_02`,
            `faq_05_li_03`,
          ],
        },
      ],
    },
  ]);

  return (
    <div className={classes.most}>
      <div className={classes.mostBody}>
        <div className="container">
          <h2 className={[classes.mostTitle, "font-64"].join(" ")}>
            <Trans>faq_banner_title</Trans>
          </h2>

          {spoilers.map((i, index) => {
            return (
              <div key={index} className={classes.mostSpoiler}>
                <p
                  className={
                    i.active
                      ? [
                          classes.mostSpoilerTitle,
                          "font-24",
                          classes.active,
                        ].join(" ")
                      : [classes.mostSpoilerTitle, "font-24"].join(" ")
                  }
                  onClick={() => {
                    setSpoilers(
                      spoilers.map((curI) => {
                        if (curI.id === i.id) {
                          return { ...curI, active: !curI.active };
                        }
                        return { ...curI, active: false };
                      })
                    );
                  }}
                >
                  <Trans>
                    {t(i.title)}
                  </Trans>
                </p>
                {i.contents.map((subI, subIndex) => {
                  return (
                    <div
                      key={subIndex}
                      className={
                        i.active
                          ? subI.isStart
                            ? [
                                classes.mostSpoilerContent,
                                classes.active,
                                classes.star,
                                "font-20",
                              ].join(" ")
                            : [
                                classes.mostSpoilerContent,
                                classes.active,
                                "font-20",
                              ].join(" ")
                          : classes.mostSpoilerContent
                      }
                    >
                      {t(subI.text)}
                      {subI.listNumbers && (
                        <ol>
                          {subI.listNumbers.map((listNum, listIndex) =>
                            <li key={listIndex} className="font-20">
                              {t(listNum)}
                            </li>
                          )}
                        </ol>
                      )}
                      {subI.listCircle && (
                        <ul style={{ listStyleType: "circle" }}>
                          {subI.listCircle.map((listNum, listIndex) =>
                            <li key={listIndex} className="font-20">
                              {t(listNum)}
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
          <div className={classes.mostBtn}>
            <Button>
              <Trans>{t('btn_start_trading')}</Trans>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Most;

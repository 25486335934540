import React, { useEffect } from "react";
import AccountTypes from "../../components/AccountComponent/AccountTypes/AccountTypes";

const AccountPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <AccountTypes />
        </div>
    );
};

export default AccountPage;

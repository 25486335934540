import React, { useEffect } from "react";
import First from "../../components/ToolsPageComponents/First/First";
import Four from "../../components/ToolsPageComponents/Four/Four";
import Second from "../../components/ToolsPageComponents/Second/Second";
import Three from "../../components/ToolsPageComponents/Three/Three";
import Stages from "../../components/ToolsPageComponents/Stages/Stages";
import classes from "./ToolsPage.module.scss";
const ToolsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.tools}>
      <First />
      <Second />
      <Three />
      <Four />
      <Stages />
    </div>
  );
};

export default ToolsPage;

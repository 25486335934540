import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from './WhyChoose.module.scss';
import icon_01 from '../../../assets/img/MainPage/WhyChoose/icon_01.png';
import icon_02 from '../../../assets/img/MainPage/WhyChoose/icon_02.png';
import icon_03 from '../../../assets/img/MainPage/WhyChoose/icon_03.png';
import icon_04 from '../../../assets/img/MainPage/WhyChoose/icon_04.png';
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const cards = [
    {
        icon: icon_01,
        text: 'why_choose_li_01',
    },
    {
        icon: icon_02,
        text: 'why_choose_li_02',
    },
    {
        icon: icon_03,
        text: 'why_choose_li_03',
    },
    {
        icon: icon_04,
        text: 'why_choose_li_04',
    },
];

const WhyChoose = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.whyChoose}>
                <h2 className={[classes.title, 'font-64'].join(' ')}>
                    <Trans>why_choose_title</Trans>
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <img className={classes.icon} src={card.icon} alt={card.text}/>
                            <h3 className={classes.text}>
                                <Trans>{t(card.text)}</Trans>
                            </h3>
                        </div>
                    )}
                </div>
                <div className={classes.btn}>
                    <Button>
                        <Trans>btn_start_trading</Trans>
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default WhyChoose;
